<template>
  <div id="container" class="gnb_pdt">
    <div class="title_wrap"><h1>물류템플릿관리 {{ isNew ? '등록' : '수정' }}</h1></div>
    <div class="contents_wrap">
      <div class="small_title_wrap"><h2>기본 정보</h2></div>
      <ul class="filter_list">
        <li>
          <dd><label>회사<span class="forg"> *</span></label>
            <ul>
              <li v-if="isNew" v-show="isNew">
                <common-multi-drop-down
                    ref="cmpyCdRef"
                    :params="{searchCmpyGbnGrup : [Constant.efs.cmpyGbn.CLIENT_COMPANY]}"
                    :data-columns="[{ field: 'cmpyNm', title: '회사명', width: '60%' },{ field: 'cmpyCd', title: '회사코드', width: '40%' }]"
                    :url="`${ApiConfig.efsDomain}/efs/drop-down/company`"
                    :data-value-field="'cmpyCd'"
                    :data-text-field="'cmpyNm'"
                    v-model="logiInfo.cmpyCd"
                    @change="e=>{
                      $refs.logiDepth1Ref.kendoWidget().value('')
                      logiDepth2List = []
                    }"
                >
                </common-multi-drop-down>
              </li>
              <li v-else>
                {{ logiInfo.cmpyNm }}
              </li>
            </ul>
          </dd>
          <dd v-if="!isNew"><label>회사코드</label>
            <ul>
              <li>
                {{ logiInfo.cmpyCd }}
              </li>
            </ul>
          </dd>

          <dd v-if="isNew" v-show="isNew"><label>적용그룹코드(템플릿)<span class="forg"> *</span></label>
            <ul>
              <li>
                <kendo-dropdownlist
                    ref="logiDepth1Ref"
                    :disabled="!logiInfo.cmpyCd"
                    :data-source="$store.state.codeRequestStore.search.combobox.logiDepth1.data"
                    :data-text-field="'cdNm'"
                    :data-value-field="'cdId'"
                    :option-label="'선택'"
                    v-model="logiInfo.logiDepth1"
                    style="width: 100%;"
                    @change="onChangeLogiDepth1"
                >
                </kendo-dropdownlist>
              </li>
            </ul>
          </dd>
          <dd v-else><label>적용그룹코드(템플릿)</label>
            <ul>
              <li>
                {{logiInfo.logiDepth1Nm}}
              </li>
            </ul>
          </dd>
          <dd v-if="!isNew"><label>그룹코드</label>
            <ul>
              <li>
                {{ logiInfo.logiDepth1 }}
              </li>
            </ul>
          </dd>
        </li>
        <li v-if="!isNew">
          <dd><label>사용여부</label>
            <ul>
              <li>
                <common-radio-group
                    ref="activeYnRef"
                    :data-items='[{label: "활성화", value: "Y",},{label: "비활성화", value: "N",}]'
                    v-model.trim="logiInfo.activeYn"
                ></common-radio-group>
              </li>
            </ul>
          </dd>
        </li>
        <li v-if="!isNew">
          <dd><label>최초등록</label>
            <ul>
              <li>{{logiInfo.lastMdfyDt}} &nbsp | &nbsp {{logiInfo.lastMdfyNm }}</li>
            </ul>
          </dd>
          <dd><label>최종수정</label>
            <ul>
              <li>{{logiInfo.lastMdfyDt}} &nbsp | &nbsp {{logiInfo.lastMdfyNm }}</li>
            </ul>
          </dd>
        </li>
      </ul>
    </div>

    <div class="contents_wrap">
      <div class="small_title_wrap">
        <h2>적용항목</h2>
      </div>
      <common-grid-custom
          :max-height="386"
          :class="'mgt20'"
          :scrollable="true"
          :data-source="logiDepth2List"
          :columns="logiDepth2GridColumns"
          :noRecords="{template:'<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>',}"
      >
        <template #logiDepth2DisNm="{rowItem}">
          <input
              :ref='"logiDepth2DisNmRef"+ rowItem.index'
              class="k-textbox w100"
              v-model.trim="rowItem.data.logiDepth2DisNm"
          />
        </template>
        <template #seqNo="{rowItem}">
          <kendo-numerictextbox
              :ref='"seqNoRef"+ rowItem.index'
              :format="'###,#'"
              :min="1"
              :max="9999"
              v-model.trim="rowItem.data.seqNo"
          ></kendo-numerictextbox>
        </template>
        <template #useYn="{rowItem}">
          <kendo-switch
              v-model="rowItem.data.VModelUseYn">
          </kendo-switch>
        </template>
      </common-grid-custom>
    </div>

    <!--- 버튼영역 --->
    <div class="button_area w25">
      <ul>
        <li class="w30">
          <button class="large_btn"
                  @click="$router.go(-1)">취소</button>
        </li>
        <li class="w30">
          <button class="large_btn orange"
                  @click="onClickLogiInfoSave">저장</button>
        </li>
      </ul>
    </div>

  </div>
</template>

<script>

import ApiUtil from '/src/api/api.util'
import ApiConfig from '/src/api/api.config'
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'
import Constant from '@/common/constant'

export default {
  name      : 'LogisticsTemplateManageSave',
  watch : {
    logiDepth2List : function(newValue, oldValue){
      EtnersCommonUtil.setRowNumber(newValue)
    }
  },
  created () {
    if (!!this.$route.params.cmpyCd && !!this.$route.params.logiDepth1) {
      this.isNew = false
      this.getLogiManageDetail(this.$route.params.cmpyCd, this.$route.params.logiDepth1)
    }
  },
  methods   : {
    onChangeLogiDepth1 : function (e){
      const vm = this
      let selectedRow = e.sender.select()
      let rowData = e.sender.dataItem(selectedRow)
      vm.existCheckLogiInfo(rowData.cdId)
    },
    getLogiDepth2List : function (logiDepth1){
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/module/logi/${logiDepth1}`)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              vm.logiDepth2List = response.data.resultData
              vm.logiDepth2List.forEach((item, i)=>{
                item.VModelUseYn = item.useYn === 'Y'
                item.logiDepth2DisNm = item.logiDepth2DisNm ?? item.logiDepth2Nm
              })
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    },
    getLogiManageDetail : function(cmpyCd,logiDepth1) {
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/module/logi/${cmpyCd}/${logiDepth1}`)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              vm.logiInfo = response.data.resultData
              vm.logiDepth2List = response.data.resultData.logiDepth2List
              vm.logiDepth2List.forEach((item, i)=>{
                item.VModelUseYn = item.useYn === 'Y'
                item.logiDepth2DisNm = item.logiDepth2DisNm ?? item.logiDepth2Nm
              })
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    },
    existCheckLogiInfo : function (logiDepth1){
      const vm = this

      let paramCmpyCd = vm.logiInfo.cmpyCd
      let paramLogiDepth1 = logiDepth1
      if(EtnersCommonUtil.isNotEmpty(paramCmpyCd) && EtnersCommonUtil.isNotEmpty(paramLogiDepth1)){

        ApiUtil.query(`${ApiConfig.efsDomain}/efs/module/logi/dup-check/${paramCmpyCd}/${paramLogiDepth1}`)
        .then(function (response) {
              if (response.data.resultStatus.messageCode === '2000') {
                if(response.data.resultData.existYn === 'Y'){
                  kendo.confirm("이미 해당 템플릿이 존재합니다. 해당 정보를 불러오시겠습니까?").done(function () {
                    vm.isNew = false
                    vm.getLogiManageDetail(paramCmpyCd,paramLogiDepth1)
                  }).fail(function (){
                    vm.$refs.logiDepth1Ref.kendoWidget().value('')
                    vm.isNew = true
                  })
                }else {
                  vm.getLogiDepth2List(paramLogiDepth1)
                  vm.isNew = true
                }
              } else {
                kendo.alert(response.data.resultStatus.messageText)
              }
            },
        )
      }
    },
    onClickLogiInfoSave () {
      const vm = this
      if (vm.validator()) {
        kendo.confirm('저장 하시겠습니까?').done(
          function () {
            vm.logiManageSave()
          })
      }
    },
    logiManageSave() {

      const vm = this

      vm.logiDepth2List.forEach(item=>{
        item.useYn = item.VModelUseYn ? 'Y' : 'N'
      })
      vm.logiInfo.logiDepth2List = vm.logiDepth2List

      if(vm.isNew){
        ApiUtil.post(`${ApiConfig.efsDomain}/efs/module/logi`, vm.logiInfo).then(response => {
          if (!response.data?.resultStatus) {
            kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
            return false
          }
          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            return false
          }
          vm.$router.push({
            name: 'LogisticsTemplateManageDetail',
            params: {
              cmpyCd : response.data.resultData.cmpyCd,
              logiDepth1 : response.data.resultData.logiDepth1
            }
          })
        })
      }else {
        ApiUtil.put(`${ApiConfig.efsDomain}/efs/module/logi/${vm.logiInfo.cmpyCd}/${vm.logiInfo.logiDepth1}`, vm.logiInfo).then(response => {
          if (!response.data?.resultStatus) {
            kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
            return false
          }
          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            return false
          }
          vm.$router.push({
            name: 'LogisticsTemplateManageDetail',
            params: {
              cmpyCd : vm.logiInfo.cmpyCd,
              logiDepth1 : vm.logiInfo.logiDepth1
            }
          })
        })
      }
    },
    validator(){
      const vm = this
      if(!vm.logiInfo.cmpyCd){
        kendo.alert("회사를 선택해주세요.").bind('close', function() {
          vm.$refs.cmpyCdRef.widget().toggle()
        })
        return
      }
      if(!vm.logiInfo.logiDepth1){
        kendo.alert("적용그룹코드를 선택해주세요.").bind('close', function() {
          vm.$refs.logiDepth1Ref.kendoWidget().toggle()
        })
        return
      }

      for(let i=0; i < vm.logiDepth2List.length; i++){
        let item = vm.logiDepth2List[i]
        if(EtnersCommonUtil.isEmpty(item.logiDepth2DisNm)){
          kendo.alert(`<b>코드번호 ${item.logiDepth2}</b>의 항목명을 입력해주세요.`).bind('close', function() {
            vm.$refs[`logiDepth2DisNmRef${i}`].focus()
          })
          return false
        }
        let sameSeqNoList = vm.logiDepth2List.filter( tItem=>{ return tItem.seqNo == item.seqNo})
        if(sameSeqNoList.length > 1){
          let message = `항목순서가 같은 코드가있습니다. 항목순서를 변경해주세요.<br> 동일항목 코드 : <b>`
          for(let j=0; j < sameSeqNoList.length; j++){
            message += `${sameSeqNoList[j].logiDepth2}`
            if(j === sameSeqNoList.length - 1){
              message += `</b>`
            }else {
              message += `, `
            }
          }
          kendo.alert(message).bind('close', function() {
            vm.$refs[`seqNoRef${i}`].kendoWidget().focus()
          })
          return false
        }
      }

      return true
    },
  },
  data      : function () {
    const headerAttributes = { style: 'font-weight: bold; text-align: center;' }
    const centerAttributes = { style: 'text-align: center;' }
    return {
      Constant,
      ApiConfig : { efsDomain : ApiConfig.efsDomain },
      isNew : true,
      logiInfo  : {
        activeYn : 'Y'
      },
      rowItem : {
        data : {},
        index : null,
      },
      logiDepth2List : [],
      logiDepth2GridColumns : [
        {
          field: 'rowNumber',
          title: 'No',
          width: '3%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'logiDepth2',
          title: '코드번호',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
          {
          field: 'logiDepth2Nm',
          title: '항목코드명',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'logiDepth2DisNm',
          title: '항목명',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'seqNo',
          title: '항목순서',
          width: '8%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'useYn',
          title: '사용여부',
          width: '5%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
      ],
    }
  },
}
</script>

<style scoped>
</style>
